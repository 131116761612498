<script>
import appConfig from "@/app.config";
import { EncryptStorage } from 'encrypt-storage';
export const encryptStorage = new EncryptStorage('secret-key-value', {
  prefix: appConfig.prefix,
});
export default {
  props: {
    
  },
  watch:{
  },
  data() {
    return {
      title: "",
      shortcuts: [
        {
          text: 'Today',
          onClick() {
            const d = new Date();
            d.setHours(0);
            d.setMinutes(0);
            d.setSeconds(0);
            // return a Date
            return [d,d];
          },
        },
        {
          text: 'Yesterday',
          onClick() {
            const d = new Date();
            d.setHours(0);
            d.setMinutes(0);
            d.setSeconds(0);
            d.setTime(d.getTime() - 3600 * 1000 * 24);
            return [d,d];
          },
        },
        {
          text: 'Last Weeks',
          onClick() {
            const d = new Date();
            d.setHours(0);
            d.setMinutes(0);
            d.setSeconds(0);
            const to = d.setTime(d.getTime() - 3600 - (d.getDay() ? d.getDay() : 7) * 24 * 60 * 60 * 1000);
            const from = d.setTime(d.getTime() - 6 * 24 * 60 * 60 * 1000);
            console.log(new Date(from),new Date(to))
            return [new Date(from), new Date(to)];
          },
        },
        {
          text: 'Last Month',
          onClick() {
            const d = new Date();
            d.setHours(0);
            d.setMinutes(0);
            d.setSeconds(0);
            const to = d.setTime(new Date(d.getFullYear(), d.getMonth(), 0));
            const from = d.setTime(new Date(d.getFullYear(), d.getMonth(), 1));
            console.log(new Date(from),new Date(to))
            return [new Date(from), new Date(to)];
          }
        },
        {
          text: 'Last 7 Days',
          onClick() {
            const d = new Date();
            d.setHours(0);
            d.setMinutes(0);
            d.setSeconds(0);
            const to = d.setTime(d.getTime() - 3600 * 1000 * 24);
            const from = d.setTime(d.getTime() - 6 * 24 * 3600 * 1000);
            return [new Date(from), new Date(to)];
          },
        },
        {
          text: 'Last 30 Days',
          onClick() {
            const d = new Date();
            d.setHours(0);
            d.setMinutes(0);
            d.setSeconds(0);
            const to = d.setTime(d.getTime() - 3600 * 1000 * 24)
            const from = d.setTime(d.getTime() - 29 * 24 * 3600 * 1000);
            return [new Date(from), new Date(to)];
          },
        },
      ],
    }
  },
  mounted(){
    this.getSetLang()
  },
  created(){
    
  },
  methods:{
    setLang(lang){
     encryptStorage.setItem('lang', lang);
     this.$i18n.locale = lang
    },
    getSetLang(){
      const lang = encryptStorage.getItem('lang');
      console.log(lang)
      this.$i18n.locale = lang
      return lang
    },
    getPreviosLink(){
      return encryptStorage.getItem('PL');
    },

    setData(data){
     encryptStorage.setItem('token', data.access_token);
     encryptStorage.setItem('username', data.username);
     encryptStorage.setItem('phone', data.phone);
     encryptStorage.setItem('userdata',JSON.stringify(data));
     encryptStorage.setItem('lang', data.lang);
     encryptStorage.setItem('mds', JSON.stringify(data.modules));
    //encryptStorage.setItem('loanStatus', data.status);
    // encryptStorage.setItem('token-not-encrypted', 'edbe38e0-748a-49c8-9f8f-b68f38dbe5a2', true);
    },
    updateData(val){
      var data = this.getFullData()
      var dataObj = JSON.parse(val)
      console.log(dataObj)
      for (let key in dataObj) {
        data[key] = dataObj[key];
      }
      encryptStorage.setItem('userdata',JSON.stringify(data))
    },
    getToken(){
      var data = encryptStorage.getItem('userdata')|| {
        access_token:""
      }
      return data.access_token || ""
    },
    getPhone(){
      var data = encryptStorage.getItem('userdata') || {
        phone:""
      }
      return data.phone || ""
    },
    getUsername(){
      var data = encryptStorage.getItem('userdata') || {
        username:""
      }
      return data.username || ""
    },
    getLoanStatus(){
      var data = encryptStorage.getItem('userdata') || {
        loanStatus:99
      }
      return data.loanStatus
    },
    getFullData(){
      var data = encryptStorage.getItem('userdata')|| {}
      return data;
    },
    setProfileInfo(data){
     encryptStorage.setItem('profileInfo',JSON.stringify(data));
    },
    updateProfileInfo(val){
      var data = this.getLoanInfo()
      var dataObj = JSON.parse(val)
      console.log(dataObj)
      for (let key in dataObj) {
        data[key] = dataObj[key];
      }
      encryptStorage.setItem('profileInfo',JSON.stringify(data))
    },
    getProfileInfo(){
      var data = encryptStorage.getItem('profileInfo')|| {}
      return data;
    },

    clearData(){
      encryptStorage.removeMultipleItems(['token','username','phone','userdata','profileInfo','loanStatus']);
    },
    clear(){
      encryptStorage.clear();
    },
    // getData(){
    //   const value = encryptStorage.getMultipleItems(['token', 'user', 'token-not-encrypted']);
    //   const value2 = encryptStorage.getItem('token');
    //   console.log(JSON.stringify(value))
    //   console.log(value2)
    // },
    
    convertThousandMillion(number) {
        if (number >= 1000000) {
            return (number / 1000000).toFixed(2) + "M";
        } else if (number >= 1000) {
            return (number / 1000).toFixed(2) + "K";
        } else {
            return number.toString();
        }
    },
    convertCurrencyFormat(val){
      const number = parseFloat(val)
      const currencyFormat = number.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits:2,
      maximumFractionDigits:2,
      });
      const currencyWithoutSymbol = currencyFormat.replace('$', '');
      return currencyWithoutSymbol
    },
    datePickerShortcut(){
      return this.shortcuts
    },
    last30Days() {
        const today = new Date();
        const endDate = new Date(today);
        const startDate = new Date(today);
        startDate.setDate(today.getDate() - 30);
        const formattedStartDate = startDate.toISOString().split('T')[0];
        const formattedEndDate = endDate.toISOString().split('T')[0];
        return [formattedStartDate, formattedEndDate];
    },
    getMenuItems(){
     var menuItems = [
          {
              id: 3,
              label: "Payin",
              icon: "uil uil-coins",
              link: "/payin"
          },
          {
              id: 4,
              label: "Payout",
              icon: "uil uil-coins",
              subItems: [
                  {
                      id: 41,
                      label: "Payouts",
                      link: "/payouts",
                      parentId: 4
                  },
                  {
                      id: 42,
                      label: "Pending Payouts",
                      link: "/pending-payouts",
                      parentId: 4
                  }
              ]
          },
          {
              id: 5,
              label: "Settlements",
              icon: "uil uil-receipt-alt",
              link: "/",
              subItems: [
                  {
                      id: 51,
                      label: "Settlements",
                      link: "/settlements",
                      parentId: 5
                  },
                  {
                      id: 52,
                      label: "Pending Settlements",
                      link: "/pending-settlements",
                      parentId: 5
                  }
              ]
          },
          {
              id: 6,
              label: "Settings",
              icon: "uil uil-setting",
              link: "/settings"
          }
      ];
      return menuItems;
    }
  }
};
</script>

<template>
  <!-- start page title -->
  <div></div>
  <!-- end page title -->
</template>